import { StartTicketValidation } from "typing";

export const VALIDATE_INPUT_ID: Record<
  StartTicketValidation,
  string | undefined
> = {
  // autocomplete feature only
  [StartTicketValidation.CUSTOMER_EMAIL]: "email",
  [StartTicketValidation.NO_VALIDATION]: undefined,
};
