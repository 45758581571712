import type { NextPage } from "next";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTicketRequestStartMutation } from "./graphql/generated/mutations.platform.generated";
import { VALIDATE_INPUT_ID } from "./utils";
import {
  Start,
  HelpStartModal,
  RequestStartValidationsCheckboxes,
} from "components";
import { useShop } from "contexts";
import { FormItem, Input } from "design";
import type { Language } from "graphql/generated/schema.platform";
import type { ShopRequestStartValidation } from "typing";
import { StartTicketValidation } from "typing";
import {
  initializeKount,
  firstElement,
  regexSnippets,
  validateRequestStartValidations,
} from "utils";
import nextI18n from "utils/i18n";
import { usePlatform } from "wrappers";

const { i18n, useTranslation } = nextI18n;

type FormValues = {
  orderName: string;
  validation?: string | null;
  requestStartValidations?: ShopRequestStartValidation[] | null;
};

const ViewStart: NextPage = () => {
  const { t } = useTranslation("start");
  const router = useRouter();
  const { query } = router;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const {
    orderExampleText,
    orderExampleImage,
    orderNamePlaceholder,
    requestStartValidations,
  } = useShop();
  const { sharedState } = usePlatform();

  const showHelp = !!(orderExampleText || orderExampleImage);

  const [fetch, { loading: loadingMutation }] = useTicketRequestStartMutation();

  useEffect(() => {
    router.prefetch("/order/[orderHash]/tickets");
    router.prefetch("/request/[ticketRequestId]/return");
  }, [router]);

  const form = useForm<FormValues>({
    defaultValues: {
      orderName: firstElement(query.order),
      validation: firstElement(query.validation),
    },
  });
  const {
    getValues,
    register,
    formState: { errors },
  } = form;

  const { ticketStartValidation, ticketStartValidationAllowsEmpty } = useShop();

  let validationInputRules: Record<string, unknown> = {};
  if (!ticketStartValidationAllowsEmpty) {
    validationInputRules = {
      required: t("form.email.required") as string,
    };
    if (ticketStartValidation === StartTicketValidation.CUSTOMER_EMAIL) {
      validationInputRules = {
        ...validationInputRules,
        pattern: {
          value: regexSnippets.email,
          message: t("form.email.invalid"),
        },
      };
    }
  }

  useEffect(() => {
    const { orderName, validation } = getValues();
    if (orderName && validation && buttonRef.current) {
      buttonRef.current.click();
    }
  }, []);

  return (
    <Start>
      <Start.Form
        buttonRef={buttonRef}
        form={form}
        loadingMutation={loadingMutation}
        checkboxesValid={validateRequestStartValidations(
          requestStartValidations,
          sharedState.requestStartValidation
        )}
        onValidSubmit={async (v) =>
          fetch({
            variables: {
              input: {
                ...v,
                forceCreate: false,
                fromGift: false,
                language: i18n.language.toUpperCase() as Language,
                startValidations: sharedState.requestStartValidation.map(
                  (e) => ({ id: e.id.toString(), acceptedAt: e.acceptedAt })
                ),
              },
            },
          }).then(({ data }) => {
            const {
              errors: _errors,
              orderHash,
              ticketRequest,
            } = data?.ticketRequestCreate || {};
            if (_errors?.length)
              return _errors.map(({ messages }) => messages).flat();
            if (orderHash) router.push(`/order/${orderHash}/tickets`);
            else {
              if (!ticketRequest) throw new Error("ticketRequest not received");
              // TODO: decode base64 and get id
              initializeKount(ticketRequest.id);
              router.push(`/request/${ticketRequest.id}/return`);
            }
            return null;
          })
        }
      >
        <FormItem error={errors.orderName?.message}>
          <Input
            {...register("orderName", {
              required: t("form.order_number.required") as string,
            })}
            label={t("form.order_number.label")}
            hasError={!!errors.orderName}
            suffix={showHelp && <HelpStartModal />}
            placeholder={orderNamePlaceholder}
          />
        </FormItem>
        {ticketStartValidation !== StartTicketValidation.NO_VALIDATION && (
          <FormItem error={errors.validation?.message}>
            <Input
              {...register("validation", validationInputRules)}
              onKeyPress={(e) => {
                // we use onKeyPress so the cursor doesn't move when inserting a space
                if (e.key === " ") e.preventDefault();
              }}
              label={t("form.email.label")}
              hasError={!!errors.validation}
              id={VALIDATE_INPUT_ID[ticketStartValidation]}
            />
          </FormItem>
        )}

        {requestStartValidations.length >= 1 && (
          <RequestStartValidationsCheckboxes />
        )}
      </Start.Form>
    </Start>
  );
};

export default ViewStart;
