import { LayoutBase } from "layouts";
import type { ReverssoPage } from "typing";
import ViewTicketRequestStart from "views/TicketRequest/Start";

const Index: ReverssoPage<typeof LayoutBase> = ViewTicketRequestStart;
Index.layout = {
  Component: LayoutBase,
  props: {
    hideHeader: true,
    isStartView: true,
    showBackgroundImage: true,
    layoutSize: "large",
  },
};

Index.getInitialProps = async () => ({
  namespacesRequired: ["common", "start", "password"],
});

export default Index;
