/* tslint:disable */
/* eslint-disable */
import * as Types from '../../../../../graphql/generated/schema.platform';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TicketRequestStartMutationVariables = Types.Exact<{
  input: Types.TicketRequestCreateInput;
}>;


export type TicketRequestStartMutation = { __typename?: 'Mutation', ticketRequestCreate: { __typename?: 'TicketRequestCreatePayload', orderHash: string | null, ticketRequest: { __typename?: 'TicketRequest', id: string } | null, errors: Array<{ __typename?: 'Error', field: string, messages: Array<string> }> | null } };


export const TicketRequestStartDocument = gql`
    mutation TicketRequestStart($input: TicketRequestCreateInput!) {
  ticketRequestCreate(input: $input) {
    ticketRequest {
      id
    }
    orderHash
    errors {
      field
      messages
    }
  }
}
    `;
export type TicketRequestStartMutationFn = Apollo.MutationFunction<TicketRequestStartMutation, TicketRequestStartMutationVariables>;

/**
 * __useTicketRequestStartMutation__
 *
 * To run a mutation, you first call `useTicketRequestStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTicketRequestStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ticketRequestStartMutation, { data, loading, error }] = useTicketRequestStartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTicketRequestStartMutation(baseOptions?: Apollo.MutationHookOptions<TicketRequestStartMutation, TicketRequestStartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TicketRequestStartMutation, TicketRequestStartMutationVariables>(TicketRequestStartDocument, options);
      }
export type TicketRequestStartMutationHookResult = ReturnType<typeof useTicketRequestStartMutation>;
export type TicketRequestStartMutationResult = Apollo.MutationResult<TicketRequestStartMutation>;
export type TicketRequestStartMutationOptions = Apollo.BaseMutationOptions<TicketRequestStartMutation, TicketRequestStartMutationVariables>;